<template>
    <div id="account-details">
        <PageMenu :breadcrumbs="breadcrumbs" :page="page"/>
        <div class="page-with-menu">
            <div class="row">
                <div class="col-md-4 col-lg-3">
                    <SidebarAccount active="account" />
                </div>
                <div class="col-md-8 ml-sm-auto col-lg-9 px-md-4">
                    <div class="card">
                        <div class="card-body">
                            <FormUserAccount/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageMenu from '@/common/components/PageMenu';
import SidebarAccount from './../../components/Sidebar.Account';
import FormUserAccount from './../../components/forms/Form.User.Account';
export default {
    components: {
        SidebarAccount,
        FormUserAccount,
        PageMenu
    },
    data(){
        return {
            page: {
                title: "My Account",
            },
            breadcrumbs: [
                {
                    title: "Dashboard",
                    url: this.$router.resolve({name: "app.dashboard"}).href
                },
                {
                    title: "My Account",
                    url: this.$router.resolve({name: "app.user.account"}).href
                },
                {
                    title: "Profile Settings",
                }
            ]
        };
    },
    mounted(){
        this.$emit('set-sidebar',{active: null, type: "channels"});
        this.$emit('set-navbar',{active: null, type: "channels"});
    },
};
</script>

