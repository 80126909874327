<template>
    <FormTemplate v-if="form" :form="form">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="label-for-xl" for="inputFirstName">First Name</label>
                    <b-form-input
                        type="text" id="inputFirstName" class="form-control form-control-xl"
                        placeholder="enter your first name"
                        v-model="form.data.firstName"
                        :state="form.states.firstName"
                    />
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="label-for-xl" for="inputLastName">Last Name</label>
                    <b-form-input
                        type="text" id="inputLastName" class="form-control form-control-xl"
                        placeholder="enter your last name"
                        v-model="form.data.lastName"
                        :state="form.states.lastName"
                    />
                </div>
            </div>
        </div>
    </FormTemplate>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';

export default {
    name: 'Forms.Form.User.Account',
    components: {
        FormTemplate
    },
    data(){
        return {
            form: null
        };
    },
    mounted(){
        this.form = new Form({
            firstName: this.user.get('firstName') || "",
            lastName: this.user.get('lastName') || "",
        },{
            onSubmit: (form) => {
                return this.$api.put('me', {
                    data: {firstName: form.get('firstName'),lastName: form.get('lastName')}
                })
                //return api.users.me.update.profile({firstName: form.get('firstName'),lastName: form.get('lastName')});
            },
            onSuccess: (form, {data}) => {
                this.$store.dispatch('User/Profile/setProfile', data);
            },
            onError: function(form, err){
                console.log(err);
            }
        });
    },
    computed: {
        user(){
            return this.$store.getters['User/Profile/getProfile'];
        }
    }
};
</script>
